<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\modal-page-initial-settings.vue
  FILE_NAME : modal-page-initial-settings
  AUTHOR : bykim
  DATE : 2024-01-18
  DESCRIPTION : 근무스케줄 등록 초기설정 페이지
-->
<template>
  <div class="container">
    <div>
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 140px" />
          <col style="width: auto" />
          <col style="width: 140px" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">
            <label for="label5">스케쥴 적용 기간<span class="icon_require">필수항목</span></label>
          </th>
          <td colspan="3">
            <DxDateBox
              ref="startDate"
              styling-mode="outlined"
              v-model="paramData.startDate"
              type="date"
              width="140"
              dateSerializationFormat="yyyyMMdd"
              displayFormat="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
              <DxValidator>
                <DxRequiredRule message="적용기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <span class="dash"> ~ </span>
            <DxDateBox
              ref="endDate"
              styling-mode="outlined"
              v-model="paramData.endDate"
              type="date"
              width="140"
              dateSerializationFormat="yyyyMMdd"
              displayFormat="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
              <DxValidator>
                <DxRequiredRule message="적용기간은 필수입니다." />
              </DxValidator>
            </DxDateBox>
          </td>
        </tr>
        <tr :hidden="true">
          <!-- TODO : 일괄근무배정 기능개발 후 구현필요. -->
          <th scope="row">
            <label for="label5">휴일근무배정<span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxSelectBox
              v-model="holidayBatchSelectBox.value"
              :items="holidayBatchSelectBox.items"
              :display-expr="holidayBatchSelectBox.displayExpr"
              :value-expr="holidayBatchSelectBox.valueExpr"
              :styling-mode="'outlined'"
              :width="130"
            >
              <DxValidator>
                <DxRequiredRule message="휴일근무배정은 필수입니다." />
              </DxValidator>
            </DxSelectBox>
          </td>
          <th scope="row">
            <label for="label5">배정방식<span class="icon_require">필수항목</span></label>
          </th>
          <td>
            <DxSelectBox
              v-model="batchTypeSelectBox.value"
              :items="batchTypeSelectBox.items"
              :display-expr="batchTypeSelectBox.displayExpr"
              :value-expr="batchTypeSelectBox.valueExpr"
              :styling-mode="'outlined'"
              :width="130"
            >
              <DxValidator>
                <DxRequiredRule message="배정방식은 필수입니다." />
              </DxValidator>
            </DxSelectBox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="tabs-container" style="height: 450px;">
      <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="2" :disabled="true" tab-css-class="customTabCSS">
        <Tab title="조직기준">
          <esp-dx-data-grid :data-grid="teamGrid" :ref="teamGrid.refName" v-if="tabIndex === 0" @row-click="onRowClick" />
        </Tab>
        <Tab title="직원기준">
          <esp-dx-data-grid :data-grid="agentGrid" :ref="agentGrid.refName" v-if="tabIndex === 1" @row-click="onRowClick" />
        </Tab>
      </Tabs>
    </div>
    <div class="page-sub-box pt-10">
      <div class="bottom-btn-wrap text-center">
        <DxButton
          text="다 음"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="onPageNext"
        />
        <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      Tabs,
      Tab,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      DxButton,
      DxDateBox,
    },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-initial-settings',
          nextPageName: 'modal-page-select-agent',
          prevPageName: '',
          agentData: {},
          workCategory: {},
        }),
        type: Object,
      },
    },
    data() {
      return {
        tabIndex: 0,
        codeMap: {},
        paramData: {},
        holidayBatchSelectBox: {
          value: '0',
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
          items: [
            {
              codeId: '0',
              codeNm: '휴일 배정 제외',
            },
            {
              codeId: '1',
              codeNm: '휴일 배정',
            },
            {
              codeId: '2',
              codeNm: '휴일만 배정',
            },
          ],
        },
        batchTypeSelectBox: {
          value: '0',
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
          items: [
            {
              codeId: '0',
              codeNm: '일괄 배정',
            },
          ],
        },
        teamGrid: {
          refName: 'teamGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: true, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          height: '340', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmFull',
              visible: true,
              alignment: 'center',
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '대상자 수',
              dataField: 'count',
              dataType: 'number',
              visible: true,
              alignment: 'center',
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: e => {
                if (e.count === null || e.count === undefined) {
                  return 0;
                }
                return e.count;
              },
            },
          ], // 컬럼 정보
        },
        agentGrid: {
          refName: 'agentGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          height: '340', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmPath',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '직급',
              dataField: 'jikgupNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '직위',
              dataField: 'jikweeNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              caption: '업무그룹',
              dataField: 'workgroupNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    watch: {
      appointmentData: {
        handler: function (newVal, oldVal) {
          this.paramData = newVal;
        },
        deep: true,
      },
    },
    methods: {
      onPageNext() {
        this.paramData.nextPageName = 'modal-page-select-agent';

        if (this.tabIndex === 0) {
          this.paramData.deptCdList = this.$refs[this.teamGrid.refName].getGridInstance.getSelectedRowKeys();
        } else if (this.tabIndex === 1) {
          this.paramData.agtIdList = this.$refs[this.agentGrid.refName].getGridInstance.getSelectedRowKeys();
        }

        if (!(this.paramData.deptCdList?.length > 0 || this.paramData.agtIdList?.length > 0)) {
          this.$_Toast('대상을 선택하세요.');
          return;
        }

        if (
          this.paramData.startDate === null ||
          this.paramData.startDate === undefined ||
          this.paramData.endDate === null ||
          this.paramData.endDate === undefined
        ) {
          this.$_Toast('적용기간을 선택하세요.');
          return;
        }
        this.$emit('pageNext', this.paramData);
      },
      onPageClose() {
        this.$emit('pageClose');
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /** @description : 상담사 데이터 검색  */
      async setAgentDataList() {
        let vm = this;
        this.agentGrid.dataSource = new CustomStore({
          key: 'agtid',
          async load() {
            const payload = {
              actionname: 'EWM_AGENT_SEARCH_LIST_ALL',
              data: [],
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
            return [];
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      async tabSelectedIndex(index) {
        this.tabIndex = index;
      },
      /** @description : 부서코드별 상담원 수 조회  */
      async setNumberOfAgents() {
        const vm = this;
        this.teamGrid.dataSource = new CustomStore({
          key: 'deptCd',
          async load() {
            const payload = {
              actionname: 'EWM_DEPT_NUMBER_OF_AGENTS',
              data: [],
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
            return [];
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * @description : 그리드 행 클릭 이벤트
       */
      onRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }
        e.component.selectRows(keys);
      },
    },
    async mounted() {
      this.paramData = this.appointmentData;
      await this.setAgentDataList(); // 상담원 그리드 데이터 조회
      await this.setNumberOfAgents(); // 현재 상담원의 부서코드별 상담원 수 조회
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .customTabCSS {
    padding: 0;
  }
  ::v-deep {
    .ecs-tab-box {
      margin: 0 5px;
      padding-top: 10px;
    }
  }
</style>
